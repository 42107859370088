// extracted by mini-css-extract-plugin
export var column = "StateEdit__column__rUbuc";
export var container = "StateEdit__container__Jks2n";
export var flex = "StateEdit__flex__AYrrV";
export var flexColumn = "StateEdit__flexColumn__GY8hR";
export var gap1 = "StateEdit__gap1__twioP";
export var gap2 = "StateEdit__gap2__ZJvRx";
export var gap3 = "StateEdit__gap3__mmhRK";
export var gap4 = "StateEdit__gap4__iik6O";
export var gap5 = "StateEdit__gap5__hoa03";
export var pageContainer = "StateEdit__pageContainer__qZ90B";
export var pageTitle = "StateEdit__pageTitle__pDxPQ";
export var row = "StateEdit__row__FZvfK";